import React, { FunctionComponent } from "react"
import "./styles.scss"
import Animate from "~/components/olc-framework/Animate"
import Heading from "../Heading"
import BlockCTA from "../../configurable/BlockCTA"
import * as B2BHeroImg from "~/vectors/b2b-hero.inline.svg"

const B2BHero: FunctionComponent<any> = ({ className = "", children, ...props }) => {
  return (
    <Animate
      properties={["opacity", "transform"]}
      startValues={["0", "translateY(40px) rotate(0.5deg)"]}
      endValues={["1", "translateY(0) rotate(0deg)"]}
      delay="0s"
    >
      <div className="b2bContainerHero">
        <div className="b2bHero">
          <div className="b2bHero__content">
            <div className="b2bHero__text">
              <Heading className="b2bHero__text__h1" >
              Making Businesses Green
              </Heading>
              <p className="b2bHero__text__p">
              We offer competitive pricing and bespoke solutions to ensure your business reaps a greener return
              </p>
              <BlockCTA navy url={'/quote-commercial'} arrow="right" className="comHero__image__button">
                  Get a quote
              </BlockCTA>
            </div>
            <div className="b2bHero__image">
               
                <div className="b2bHero__image__pad">
                  <B2BHeroImg />
                </div>
                <div className="b2bHero__image__backg"/>
            </div>
          </div>
        </div>
      </div>
    </Animate>
  )
}

export default B2BHero
