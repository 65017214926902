import React, { FunctionComponent, useEffect, useRef, useState } from "react"
import Col5 from "~/components/grid/Col5"
import Col7 from "~/components/grid/Col7"
import "./styles.scss"



const ShoutoutNew: FunctionComponent<any> = ({ className, text, image, rtl, color, ...props }) => {

  const [height, setHeight] = useState(0);
  const elementRef = useRef(null);

  useEffect(() => {
    setHeight(elementRef.current.clientHeight);
  }, []);

  ////console.log('hello', height);


  const imagewar = require('../../../images/funded_solar.png');

  const textBlock = (
    <Col5  >
      <div className="new_shoutout__text" ref={elementRef} style={{ padding: "3em 3em 4em 3em" }}  >{text}</div>
    </Col5>
  )

  const imageBlock = (
    <Col7>
      <div className="new_shoutout__img__container" style={{ minHeight: `${(height * 1.3) + 34}px` }} >
        {/* {image} */}
        <img src={image} style={{ minHeight: `${(height * 1.3) + 34}px`, objectFit: "cover" }} />
      </div>

    </Col7>
  )

  return (
    <div className="new_shoutout" style={{backgroundColor: `${color}`}} >
      <div className="container">
        <div className="row"
          style={{ height: `${height * 1.3}px` }}
        >
          {!rtl && (
            <>
              {textBlock}
              {imageBlock}
            </>
          )}
          {rtl && (
            <>
              {imageBlock}
              {textBlock}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default ShoutoutNew
