import React, { useContext, useEffect, useState } from "react"
import Hero from "~/components/configurable/Hero"
import Heading from "~/components/configurable/Heading"
import Block from "../configurable/Block"
import Quote from "../configurable/Quote"
import Section from "../configurable/Section"
import TickList from "../configurable/TickList"
import BlockCTA from "../configurable/BlockCTA"
import Col3 from "../grid/Col3"
import Col8 from "../grid/Col8"
import Col4 from "../grid/Col4"
import * as B2B_Ill from "~/vectors/b2b.inline.svg"
import * as B2B_Process from "~/vectors/b2b-process.inline.svg"
import * as B2B_Process_Vertical from "~/vectors/b2b-process-vertical.inline.svg"
import "./for-your-business.scss"
import { CustomerTypeContext } from "~/providers/CustomerTypeProvider"
import B2BHero from "~/components/configurable/B2BHero"


import Col12 from "../grid/Col12"

import ShoutoutNew from "../configurable/ShoutoutNew"

import { GetModal } from "../configurable/Modal"
import Collapsible from "../configurable/Collapsible"


const imagewar = require('../../images/funded_solar.png');

const content = {
  btnModal1: {
    title: 'Energy Independence',
    content: (
      <>
        <div className="modal_content_container" >
          <div className="modal_copy_inverter" >
            <p>By installation a Commerical solar PV system give business Energy independence making them less reliant on energy from the grid and protects against rising energy prices  </p>
          </div>
        </div>
      </>
    )
  },
  btnModal2: {
    title: 'A Reduced Carbon Footprint ',
    content: (
      <>
        <div className="modal_content_container" >
          <div className="modal_copy_inverter" >
            <p>Solar energy is a truly renewable energy source, by installing commercial solar panels you will lower your business' carbon footprint, as well contributing to the UK and the Rest of the Worlds carbon reduction and green energy targets  </p>
          </div>
        </div>
      </>
    )
  },
  btnModal3: {
    title: 'Financial Savings  ',
    content: (
      <>
        <div className="modal_content_container" >
          <div className="modal_copy_inverter" >
            <p>A commercial Solar PV system you will significantly reduce your company's energy usage and utility spend and in turn these savings can be used to invest into growing your business further and/or contributing to operational costs of your business</p>
          </div>
        </div>

      </>
    )
  },
  btnModal4: {
    title: 'Low risk investment ',
    content: (
      <>
        <div className="modal_content_container" >
          <div className="modal_copy_inverter" >
            <p>Solar PV is a reliable and safe investment vehicle that provides returns exceeding those of traditional low risk financial products.</p>
          </div>
        </div>


      </>
    )
  },
  btnModal5: {
    title: 'Green Business & CSR',
    content: (
      <>
        <div className="modal_content_container" >
          <div className="modal_copy_inverter" >
            <p>With growing environmental concerns, consumers and even employees are becoming more conscientious with whom they shop with or work for. Using commercial solar panels to power your business on green energy will demonstrate your commitment towards green and sustainable practice.  As well as being actively better for the environment on its own merits, solar panels can serve as a valuable and highly visible demonstration of your business’ commitment to environmental causes. In today’s modern age, especially with the growing climate crisis, customers are increasingly making greener choices in terms of which businesses they choose, and voting with their wallets. For that reason alone, solar panels are great for boosting your Corporate Social Responsibility, which can make all the difference to attracting increasingly selective customers and investors.</p>
          </div>
        </div>

      </>
    )
  },
}


const HomepageB2b = ({ markdownNodes }) => {
  const { setCustomerType } = useContext(CustomerTypeContext)

  useEffect(() => {
    setCustomerType("commercial")
  }, [])

  //START MODAL LOGIC

  let subtitle;

  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f000';
  }

  const [modalContent, setModalContent] = React.useState(undefined);

  const [brandModalConfig, setBrandModalConfig] = React.useState(undefined);

  const closeModal = React.useCallback(() => {
    setModalContent(undefined);
  }, []);

  const onOpenModalFor = React.useCallback((name: string) => {
    setModalContent(content[name]);
  }, []);


  const getModalContent = () => {
    if (modalContent?.content) {
      return typeof modalContent?.content === 'function' ? modalContent?.content((brand) => {
        setBrandFilter(brand);
        closeModal();
      }, brandModalConfig) : modalContent?.content
    }
    return modalContent?.content;
  }

  const getModalTitle = () => {
    if (modalContent?.title) {
      return typeof modalContent?.title === 'function' ? modalContent?.title(brandModalConfig) : modalContent?.title;
    }
    return '';
  }

  //END MODAL LOGIC

  //START button-slider RENDER LOGIC

  const [sliderContent, setsliderContent] = useState('defaultContent');

  const defaultContent = React.useMemo(() => sliderContent === "defaultContent", [sliderContent]);
  const btn1Content = React.useMemo(() => sliderContent === "btn1Content", [sliderContent]);
  const btn2Content = React.useMemo(() => sliderContent === "btn2Content", [sliderContent]);
  const btn3Content = React.useMemo(() => sliderContent === "btn3Content", [sliderContent]);
  const btn4Content = React.useMemo(() => sliderContent === "btn4Content", [sliderContent]);


  //console.log("hello sarai", sliderContent)


  // ENDS button-slider RENDER LOGIC


  return (
    <div className="homepage-b2b" style={{overflow: "hidden"}}>
      <GetModal
        show={Boolean(modalContent)}
        onClose={closeModal}
        title={getModalTitle()}
      >
        {getModalContent()}
      </GetModal>
      <div style={{backgroundColor:'#3c96c5' }} ><B2BHero/></div>

      <Block  >
        {/* <Section className="visible-xs">
          <Quote
            title="Let us produce a solar business case for you"
            description="No one understands solar better than us, and we love efficient processes. Give us a call or request a free consultation in seconds, so you can concentrate on what matters most - operating your business."
            ctaText="Get In Touch"
            compact
          />
        </Section> */}
        <Section className="business-content-wrapper">
          <Heading underlined>A different way of doing solar </Heading>
          <p>
            At Green Energy Together we believe that solar PV systems should be affordable for everyone  
          </p>
          <p>
            As one of the largest solar development groups in the country working on numerous different types of projects ranging from commercial, industrial, utility and domestic  installations we trade significantly larger volumes than the competition offering solutions for small and medium businesses at prices usually found in large industrial settings while ensuring investor standard warranty and delivery.
          </p>
        </Section>
      </Block>
      <div className="divider" />
      <Block>
        <Section className="hidden-xs" >
          <div className="row"> <Col12>
            <Heading level={2} underlined>Tailored Made Solutions</Heading>
            <p style={{ marginBottom: "1em" }} >
              Green Energy Together work with some the world’s leading manufacturers to provide tailor made solutions to fit your business renewable energy requirements.
            </p> </Col12>

          </div>

          <div className="box-slider__main__container"  >
            <div className="box-slider__row top">
              <div className="box-slider__body"  >

                {defaultContent && (
                  <>
                    <Heading level={3} underlined>Markets sectors</Heading >
                    <p>Click on the name of your industry sector for more information on how Green Energy Together can offer you specialised support. </p>
                  </>
                )}
                {btn1Content && (
                  <>
                    <Heading level={3} underlined>Developers <br /> & Housing Builders</Heading >
                    <p className="bold-formatting">Whether a new development or retrofitting of existing housing stock, Green Energy Together is here to support your project with a wide range of solutions and schemes, including cutting edge products such as built in solar tiles and <a href="/PPA">fully funded options</a> resulting in unbeatable  profitability and efficiency.</p>
                  </>
                )}

                {btn2Content && (
                  <>
                    <Heading level={3} underlined>Industrial &  <br />
                      Commercial Buildings</Heading >
                    <p className="bold-formatting">Green Energy Together has over a decade of experience with the design and installation of a wide range of industrial renewable solutions. From one <a href="/moss">of a kind bespoke designs</a> to increase your business visibility and CSR scores, to cost effective solutions for agricultural, manufacturing and distribution facilities of all sizes. </p>
                  </>
                )}

                {btn3Content && (
                  <>
                    <Heading level={3} underlined>Large scale, Logistics<br />  &
                      Specialised Solar</Heading >
                    <p className="bold-formatting">Whatever the set up, we can make sure your asset performs optimally protecting your investment. Our in-house Design, Installation,  Commissioning and Maintenance teams provide on-going O&M to over 150MW of ground mounted solar assets, making us one of the largest solar specialists in the country. Our services include:<br/>
                    •	<a href="/service/asset-management/">Asset Management</a><br/>
                    •	<a href="/service/technical-design/">Technical Design</a><br/>
                    •	<a href="/service/operation-maintenance/">Operation & Maintenance</a>

                    </p>
                  </>
                )}

                {btn4Content && (
                  <>
                    <Heading level={3} underlined>Public Sector</Heading >
                    <p className="bold-formatting">Green Energy Together has extensive experience in the public sector, including multiple installations in public schools and universities. Over the last decade our team was honoured to be trusted with the installation of solar PV in multiple public British landmarks such as <a href="/project/kings-college-hospital/">King's College Hospital</a> and <a href="/project/paddington-station/">Paddington Train Station</a>. <br/>
                    We offer a range of specialised programmes to support the installation of solar in community buildings such as <a href="/twobuytwo">places of worship</a> and <a href="/ppa">fully funded solutions</a> for landlords and housing associations. 
</p>
                  </>
                )}
              </div>
              <div className="box-slider__column">
                <button className="box-slider btn__1"
                  onClick={e => setsliderContent('btn1Content')}
                >
                  <h3>
                    Developers <br /> & Housing Builders
                  </h3>
                </button>
                <button className="box-slider btn__2"
                  onClick={e => setsliderContent('btn2Content')} >
                  <h3>Industrial &  <br />
                    Commercial Buildings</h3>
                  <p>Including Small, Medium and Large Businesses such as hospitality, agricultural or manufacturing facilities
                  </p>
                </button>
              </div>
            </div>
            <div className="box-slider__row bottom">
              <button className="box-slider btn__3"
                onClick={e => setsliderContent('btn3Content')} >
                <h3>Large scale, Logistics &
                  Specialised Solar</h3>

              </button>
              <button className="box-slider btn__4"
                onClick={e => setsliderContent('btn4Content')} >
                <h3>Public Sector</h3>
                <p>Schools, Hospitals & public buildings</p>
              </button>
            </div>
          </div>

        </Section>

        <Section className="mobile-sectors visible-xs b2b" >
          <Heading level={2} underlined>Our services</Heading>
            <p>
              Green Energy Together work with some the world’s leading manufacturers to provide tailor made solutions to fit your business renewable energy requirements with solutions covering:
            </p> 
          <Collapsible
            heading={'Developers & Housing Builders'}
            content={ <p className="bold-formatting">Whether a new development or retrofitting of existing housing stock, Green Energy Together is here to support your project with a wide range of solutions and schemes, including cutting edge products such as built in solar tiles and <a href="/PPA">fully funded options</a> resulting in unbeatable  profitability and efficiency.</p>}
            category={'sec1'}
          />
          <Collapsible
            heading={'Industrial & Commercial Buildings'}
            content={<p className="bold-formatting">Green Energy Together has over a decade of experience with the design and installation of a wide range of industrial renewable solutions. From one <a href="/moss">of a kind bespoke designs</a> to increase your business visibility and CSR scores, to cost effective solutions for agricultural, manufacturing and distribution facilities of all sizes. </p>}
            category={'sec2'}
          />
          <Collapsible
            heading={'Large scale, Logistics & Specialised Solar'}
            content={ <p className="bold-formatting">Whatever the set up, we can make sure your asset performs optimally protecting your investment. Our in-house Design, Installation,  Commissioning and Maintenance teams provide on-going O&M to over 150MW of ground mounted solar assets, making us one of the largest solar specialists in the country. Our services include:<br/><br/>
            •	<a href="/service/asset-management/">Asset Management</a><br/>
            •	<a href="/service/technical-design/">Technical Design</a><br/>
            •	<a href="/service/operation-maintenance/">Operation & Maintenance</a>

            </p>}
            category={'sec3'}
          />
          <Collapsible
            heading={'Public Sector'}
            content={<p className="bold-formatting">Green Energy Together has extensive experience in the public sector, including multiple installations in public schools and universities. Over the last decade our team was honoured to be trusted with the installation of solar PV in multiple public British landmarks such as <a href="/project/kings-college-hospital/">King's College Hospital</a> and <a href="/project/paddington-station/">Paddington Train Station</a>. We offer a range of specialised programmes to support the installation of solar in community buildings such as <a href="/twobuytwo">places of worship</a> and <a href="/ppa">fully funded solutions</a> for landlords and housing associations. 
</p>}
            category={'sec4'}
          />
        </Section>
      </Block>
      <div className="divider" />
      <Block>
        <Section>
          <div className="row">
            <Col12>
              <Heading level={2} underlined>Solutions for all needs </Heading>
              {/* <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Gravida ultrices nullam aliquet risus. Mi et eget viverra aliquet sagittis.
              </p> */}
              <p className="bold-formatting bulletpoints-tablet" >
                <span style={{ color: "#70B33B"}}>
                  • </span>
                  Roof Top Solar PV  
                <span style={{ color: "#70B33B", marginLeft:"1em" }}>
                  • </span>
                  Ground Mount Solar PV
                <span style={{ color: "#70B33B", marginLeft:"1em" }}>
                    • </span>
                  Battery Storage
                <span style={{ color: "#70B33B", marginLeft:"1em" }}>
                  • </span>
                  EV Charging
                <span style={{ color: "#70B33B", marginLeft:"1em" }}>
                  • </span>
                  Solar Car Ports
                  <span style={{ color: "#70B33B", marginLeft:"1em" }}>
                  • </span>
                  Domestic, Commercial and Industrial PPA Funded Solar
              </p> 
            </Col12>
          </div>
          <div className="row b2b-illustration" style={{ marginTop: "4em" }} >
            <Col8 className="b2b-illustration__container" >
              <B2B_Ill style={{ padding: "0em 3em" }} />
            </Col8>
            <Col4 className="b2b-illustration__text">
              <TickList>
                <li>Roof Top Solar PV </li>
                <li>Ground Mount Solar PV</li>
                <li>Battery Storage</li>
                <li>EV Charging </li>
                <li>Solar Car Ports</li>
                <li>Domestic, Commercial and Industrial PPA Funded Solar</li>
              </TickList>

            </Col4>
          </div>

        </Section>

      </Block>

      <ShoutoutNew
        color={'#70b33b'}
        image={require('../../images/funded_solar.png')}
        text={
          <>
            <Heading underlined>Funded solar</Heading>
            <p>
              We believe sustainable options and peace of mind should be
              generally available and affordable. As one of the largest
              installers in the country, we deal directly with manufacturers,
              letting us offer highly competitive prices and unique warranties
              and service guarantees.
            </p>
            <BlockCTA navy url="/products-warranties">Find out more</BlockCTA>
          </>
        }
      />

      <Block>
        <Section>
          <div className="container ">
            <Col12>
              <Heading level={2} underlined >The benefits of commercial solar</Heading>
              <p className="bold-formatting">
              By installing solar pv for your business not only do you generate free electricity you will also benefit from the following:
              </p>
              <div className="b2b-ills row" >
                <div className="b2b-ills__item">
                  <img src={require('../../images/fuels/svg/Group_8.svg')} alt="my image" />

                  <BlockCTA className="blue-btn" action={() => onOpenModalFor('btnModal1')}>
                    Energy<br /> Independence
                  </BlockCTA>
                </div>
                <div className="b2b-ills__item">
                  <img src={require('../../images/fuels/svg/Group_9.svg')} alt="my image" />

                  <BlockCTA className="blue-btn" action={() => onOpenModalFor('btnModal2')}>
                    Reduced<br /> Footprint
                  </BlockCTA>
                </div>
                <div className="b2b-ills__item">
                  <img src={require('../../images/fuels/svg/Group_10.svg')} alt="my image" />

                  <BlockCTA className="blue-btn" action={() => onOpenModalFor('btnModal3')}>
                    Financial<br /> Savings
                  </BlockCTA>
                </div>
                <div className="b2b-ills__item">
                  <img src={require('../../images/fuels/svg/Group_11.svg')} alt="my image" />

                  <BlockCTA className="blue-btn" action={() => onOpenModalFor('btnModal4')}>
                    Green<br /> Business
                  </BlockCTA>
                </div>
                <div className="b2b-ills__item">
                  <img src={require('../../images/fuels/svg/Group_12.svg')} alt="my image" />

                  <BlockCTA className="blue-btn" action={() => onOpenModalFor('btnModal5')}>
                    Safe<br /> investment
                  </BlockCTA>
                </div>
              </div>
            </Col12>
          </div>
        </Section>
      </Block>
      <div className="divider" />
      <Block>
        <Section>
          <div className="row">
            <Col12>
              <Heading level={2} underlined>Our process</Heading>
              <p style={{ marginBottom: "1em" }}>
              Throughout every step of the way on your solar journey we will be there to support you and use following process to ensure we can provide you with best tailored made solutions to meet your needs.
              </p>
            </Col12>
          </div>
          <div className="row">

            <div className="hidden-xs" style={{ width: "100vw", display: "flex", flexDirection: "row", justifyContent: "center", padding: "3%" }}>
              <B2B_Process style={{ alignItems: "center" }} />
            </div>

            <div className="visible-xs" style={{ width: "100vw", display: "flex", flexDirection: "row", justifyContent: "center", padding: "3%" }}>
              <B2B_Process_Vertical style={{ alignItems: "center", padding: "5%" }} />
            </div>

          </div>

        </Section>
      </Block>
      <div className="divider" />
      <Block>
        <Section>
          <div className="row">
            <Col12>
              <Heading level={2} underlined>Your obligation-free final report</Heading>
              <p>
                This will include:  </p>
            </Col12>

            <Col3>
              <TickList>
                <li>System Yield</li>
              </TickList>
            </Col3>
            <Col3>
              <TickList>
                <li>Investment Return</li>
              </TickList>
            </Col3>
            <Col3>
              <TickList>
                <li>Payback Periods</li>
              </TickList>
            </Col3>
            <Col3>
              <TickList>
                <li>Net Present Value (NPV) analysis</li>
              </TickList>
            </Col3>


            <div className="row">

            </div>
          </div>
          <div className="row">
          </div>
        </Section>
      </Block>
      <div className="divider" />
      <Block>
        <Section>
          <div className="row">
            <Col12>
              <Heading level={2} underlined>Go beyond</Heading>
              <p>
                If you decide to go with us, Green Energy Together takes care of the entire project, including grid connect application, council approval, notifying your retailer, and arranging an engineering certification if required. Not all solar installers offer this level of service: however, it is a critical part of the process that ensures a safe and trouble-free installation. Finally, we offer a full guarantee over the installation and technical assistance from dedicated customer care team for 2 years afterwards. This is in addition to the guarantee offered by the manufacturer.
              </p>
            </Col12>

          </div>
        </Section>



      </Block>
    </div>
  )
}

export default HomepageB2b
